import Header from '../components/Header'
import React, { useState } from 'react'
import { Form, Input, Select, Button, message, Progress } from 'antd'
import Footer from '../components/Footer'
import Title from '../components/Title'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
const { TextArea } = Input

const Register = () => {
  const navigate = useNavigate()
  const [passwordStrength, setPasswordStrength] = useState(0) // 0: Very Weak, 1: Weak, 2: Good, 3: Strong
  const [isPointsConfirmed, setPointsConfirmed] = useState(false)
  const [isInformationCorrect, setInformationCorrect] = useState(false)
  const [isConsentAgreed, setConsentAgreed] = useState(false)
  const [isContactAllowed, setContactAllowed] = useState(false)
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false) // Add loading state

  const handleSubmit = async values => {
    setIsLoading(true)

    if (
      !isPointsConfirmed ||
      !isInformationCorrect ||
      !isConsentAgreed ||
      !isContactAllowed
    ) {
      setIsLoading(false)
      return message.error(
        'Please confirm all checkboxes before submitting the form.'
      )
    }
    // console.log('values', values)
    try {
      const response = await axios.post('/api/auth/register', values, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (response.status === 200) {
        navigate('/login')
      } else {
        message.error('An error occurred')
      }
    } catch (error) {
      setIsLoading(false)
      console.error('Error submitting form:', error)
      message.error(error.response?.data.error || 'An error occurred')
    }
  }

  const getPasswordStrength = password => {
    // Simplified logic to determine password strength
    if (password.length < 6) {
      return 0 // Very Weak
    } else if (password.length < 8) {
      return 1 // Weak
    } else if (password.match(/[a-zA-Z]+/) && password.match(/[0-9]+/)) {
      return 2 // Good
    } else {
      return 3 // Strong
    }
  }

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case 0:
        return 'red' // Very Weak
      case 1:
        return 'orange' // Weak
      case 2:
        return 'yellow' // Good
      case 3:
        return 'green' // Strong
      default:
        return 'transparent'
    }
  }

  const getPasswordStrengthText = strength => {
    switch (strength) {
      case 0:
        return 'Very Weak'
      case 1:
        return 'Weak'
      case 2:
        return 'Good'
      case 3:
        return 'Strong'
      default:
        return ''
    }
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <Header />
      <Title text="Join IMFeD Now" />

      <div>
        <Form
          form={form}
          name="basic"
          style={{
            margin: 'auto', // Center horizontally
            marginTop: '50px', // Adjust the top margin to center vertically
            paddingLeft: '25%',
            paddingRight: '25%'
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <h1>Please Signup</h1>

          <p style={{ textAlign: 'left', fontWeight: '700' }}>
            Create your username and password
          </p>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!'
              }
            ]}
            style={{
              borderRadius: '20px'
            }}
          >
            <Input className="register-border-input" placeholder="*Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!'
              }
            ]}
          >
            <Input.Password
              name="password"
              type="password"
              className="register-border-input"
              placeholder="*Password"
              onChange={e =>
                setPasswordStrength(getPasswordStrength(e.target.value))
              }
            />
          </Form.Item>

          {passwordStrength > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Progress
                strokeColor={getPasswordStrengthColor()}
                percent={(passwordStrength + 1) * 25}
                showInfo={false}
                style={{ width: '30%', alignSelf: 'flex-end' }}
              />

              <div
                style={{ color: getPasswordStrengthColor(), alignSelf: 'end' }}
              >
                Strength: {getPasswordStrengthText(passwordStrength)}
              </div>
            </div>
          ) : null}

          <h2
            style={{ textAlign: 'left', color: '#757575', fontWeight: '400' }}
          >
            Personal Details
          </h2>

          <div
            style={{
              borderBottom: '1px solid #ddd',
              marginBottom: '15px',
              marginTop: '30px'
            }}
          ></div>

          <Form.Item name="title" rules={[{ required: true }]}>
            <Select
              style={{ textAlign: 'left' }}
              className="register-border-input"
              name="title"
              placeholder="Select Title"
            >
              <Select.Option value="Mr">Mr</Select.Option>
              <Select.Option value="Mrs">Mrs</Select.Option>
              <Select.Option value="Ms">Ms</Select.Option>
              <Select.Option value="Dr">Dr</Select.Option>
              <Select.Option value="Prof">Prof</Select.Option>
              <Select.Option value="Assoc Prof">Assoc Prof</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="firstname"
            rules={[
              {
                required: true,
                message: 'Please input your first name!'
              }
            ]}
          >
            <Input
              className="register-border-input"
              placeholder="*First Name (as per NRIC)"
            />
          </Form.Item>

          <Form.Item
            name="lastname"
            rules={[
              {
                required: true,
                message: 'Please input your last name!'
              }
            ]}
          >
            <Input
              className="register-border-input"
              placeholder="*Last Name (as per NRIC)"
            />
          </Form.Item>

          <Form.Item
            name="icNumber"
            rules={[
              {
                required: true,
                message: 'Please input your IC Number!'
              }
            ]}
          >
            <Input className="register-border-input" placeholder="*NRIC" />
          </Form.Item>

          <Form.Item
            name="number"
            rules={[
              {
                required: true,
                message: 'Please input your number!'
              }
            ]}
          >
            <Input
              className="register-border-input"
              placeholder="*Phone Number"
            />
          </Form.Item>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!'
              },
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              }
            ]}
          >
            <Input
              className="register-border-input"
              placeholder="*Email Address"
              type="email"
            />
          </Form.Item>

          <Form.Item
            name="professionalRegistration"
            rules={[{ required: true }]}
          >
            <Select
              style={{ textAlign: 'left' }}
              className="register-border-input"
              name="professionalRegistration"
              placeholder="Select Number"
            >
              <Select.Option value="MMC Number">MMC Number</Select.Option>
              <Select.Option value="MPS Number">MPS Number</Select.Option>
              <Select.Option value="MyCPD Number">MyCPD Number</Select.Option>
              <Select.Option value="N/A">N/A</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="mmcNumber"
            rules={[
              {
                required: true,
                message: 'Please input your Number!'
              }
            ]}
          >
            <Input
              className="register-border-input"
              placeholder="*MMC/MPS/MyCPD Number"
            />
          </Form.Item>

          <Form.Item
            name="job"
            rules={[
              {
                required: true,
                message: 'Please input your speciality!'
              }
            ]}
          >
            <Select
              style={{ textAlign: 'left' }}
              className="register-border-input"
              placeholder="Select Speciality"
            >
              <Select.Option value="">Speciality</Select.Option>
              <Select.Option value="Paediatrician">Paediatrician</Select.Option>
              <Select.Option value="General Practitioner">
                General Practitioner
              </Select.Option>
              <Select.Option value="Medical Officer">
                Medical Officer
              </Select.Option>
              <Select.Option value="Family Medicine Specialist">
                Family Medicine Specialist
              </Select.Option>
              <Select.Option value="Dietitian">Dietitian</Select.Option>
              <Select.Option value="Nutritionist">Nutritionist</Select.Option>
              <Select.Option value="Pharmacist">Pharmacist</Select.Option>
              <Select.Option value="Others">
                Others ( Please state in box below )
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="otherProfession"
            placeholder="Select Other Profession"
          >
            <TextArea
              className="register-border-input"
              rows={4}
              placeholder="If selected other profession, please state here."
            />
          </Form.Item>

          <Form.Item
            name="sector"
            rules={[{ required: true, message: 'Please select your sector!' }]}
          >
            <Select
              style={{ textAlign: 'left' }}
              className="register-border-input"
              name="sector"
              placeholder="Please select your sector"
            >
              <Select.Option value="Government">Government</Select.Option>
              <Select.Option value="Private">Private</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="placeOfPractice"
            rules={[
              {
                required: true,
                message: 'Please input your place of practice!'
              }
            ]}
          >
            <Input
              className="register-border-input"
              placeholder="*Place of practice"
            />
          </Form.Item>

          <Form.Item
            name="address1"
            rules={[
              {
                required: true,
                message: 'Please input your Address!'
              }
            ]}
          >
            <Input
              className="register-border-input"
              placeholder="*Address Line 1"
            />
          </Form.Item>

          <Form.Item name="address2">
            <Input
              className="register-border-input"
              placeholder="Address Line 2"
            />
          </Form.Item>

          <Form.Item
            name="city"
            rules={[
              {
                required: true,
                message: 'Please input your City!'
              }
            ]}
          >
            <Input className="register-border-input" placeholder="*City" />
          </Form.Item>

          <Form.Item
            name="state"
            rules={[{ required: true, message: 'Please select your state!' }]}
          >
            <Select
              style={{ textAlign: 'left' }}
              className="register-border-input"
              name="state"
              placeholder="Select State"
            >
              <Select.Option value="johor">Johor</Select.Option>
              <Select.Option value="kedah">Kedah</Select.Option>
              <Select.Option value="kelantan">Kelantan</Select.Option>
              <Select.Option value="melaka">Melaka</Select.Option>
              <Select.Option value="negeri-sembilan">
                Negeri Sembilan
              </Select.Option>
              <Select.Option value="pahang">Pahang</Select.Option>
              <Select.Option value="perak">Perak</Select.Option>
              <Select.Option value="perlis">Perlis</Select.Option>
              <Select.Option value="pulau-pinang">Pulau Pinang</Select.Option>
              <Select.Option value="sabah">Sabah</Select.Option>
              <Select.Option value="sarawak">Sarawak</Select.Option>
              <Select.Option value="selangor">Selangor</Select.Option>
              <Select.Option value="terengganu">Terengganu</Select.Option>
              <Select.Option value="wilayah persekutuan putrajaya">
                Wilayah Persekutuan Putrajaya
              </Select.Option>
              <Select.Option value="wilayah persekutuan kuala lumpur">
                Wilayah Persekutuan Kuala Lumpur
              </Select.Option>
              <Select.Option value=" wilayah persekutuan labuan">
                {' '}
                Wilayah Persekutuan Labuan
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item name="refer" rules={[{ required: true }]}>
            <Select
              style={{ textAlign: 'left' }}
              className="register-border-input"
              name="refer"
              placeholder="How did you hear about us?"
            >
              <Select.Option value="Colleague">Colleague</Select.Option>
              <Select.Option value="Website">Website</Select.Option>
              <Select.Option value="Seminar">
                During IMFeD Seminar
              </Select.Option>
              <Select.Option value="SalesRep">Sales Rep</Select.Option>
              <Select.Option value="IMFeD course at MMA app/website">
                IMFeD course at MMA app/website
              </Select.Option>

              <Select.Option value="others">Others</Select.Option>
            </Select>
          </Form.Item>

          <p style={{ textAlign: 'left' }}>
            Note: Upon registration, you will receive a confirmation within 1
            working day.
          </p>

          <div style={{ textAlign: 'left', marginTop: '20px' }}>
            <input
              type="checkbox"
              id="pointsConfirmed"
              checked={isPointsConfirmed}
              onChange={() => setPointsConfirmed(!isPointsConfirmed)}
            />
            <label htmlFor="pointsConfirmed">
              MMA/MPS/MyCPD CPD points will be awarded for each completed module
              (ie watched video & answered 10 test questions) of the IMFeD
              Paediatric Growth & Nutrition Online Course within the online
              course period, as stated in the course page.​
            </label>
          </div>

          <div style={{ textAlign: 'left', marginTop: '20px' }}>
            <input
              type="checkbox"
              id="informationCorrect"
              checked={isInformationCorrect}
              onChange={() => setInformationCorrect(!isInformationCorrect)}
            />
            <label htmlFor="informationCorrect">
              All information provided is correct. Should there be any errors in
              the information filled in, I understand that I may be disqualified
              from the IMFeD Paediatric Growth & Nutrition Online Course CPD
              points redemption.
            </label>
          </div>

          <div style={{ textAlign: 'left', marginTop: '20px' }}>
            <input
              type="checkbox"
              id="consentAgreed"
              checked={isConsentAgreed}
              onChange={() => setConsentAgreed(!isConsentAgreed)}
            />
            <label htmlFor="consentAgreed">
              {' '}
              I agreed to Abbott’s legal notice and understand the consent
              notice. Please read{' '}
              <a href="https://www.my.abbott/privacy-policy.html">
                Abbott’s legal & consent notice
              </a>{' '}
              here
            </label>
          </div>

          <div style={{ textAlign: 'left', marginTop: '20px' }}>
            <input
              type="checkbox"
              id="contactAllowed"
              checked={isContactAllowed}
              onChange={() => setContactAllowed(!isContactAllowed)}
            />
            <label htmlFor="contactAllowed">
              I agree to allow the IMFeD Secretariat to contact me via email,
              SMS & WhatsApp.
            </label>
          </div>

          <Form.Item>
            <Button
              style={{ marginTop: '2rem', padding: '0 40px' }}
              loading={isLoading}
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '30px'
            }}
          >
            <p style={{ margin: 0, color: 'gray' }}>
              Already have an account?{' '}
            </p>
            <span
              style={{ cursor: 'pointer', marginLeft: '5px', color: 'blue' }}
              onClick={() => navigate('/login')}
            >
              {' '}
              Login
            </span>
          </div>
        </Form>
      </div>
      <Footer />
    </div>
  )
}

export default Register
